import $ from "jquery";
import {
  logFactory,
  isCompExists,
  components,
  MediaQueryHelper,
} from "GlobalSite";

const compName = "comp_aem_hp-current-offers";
const Log = logFactory(compName);
const { log } = Log;
const {
  compTechUtilTooltipmanager: { TooltipManager },
} = components;

log("--->");

$(function domReady() {
  if (!isCompExists(compName, Log)) {
    return;
  }

  // toggle staked card display on mobile
  //  will be replace by a more elaborate animation later
  const { lesserThan, on } = MediaQueryHelper;

  on(lesserThan("s", { strict: true }), {
    success() {
      const $stackedList = $(".hp-current-offers__list--mobile-stacked");

      $stackedList.on("click", function () {
        $(this).toggleClass("hp-current-offers__list--mobile-stacked");
      });
    },
  });

  new TooltipManager({
    tooltipCtaSelector: ".tooltip-cta",
    tooltipSelector: ".tooltip",
    arrowSelector: ".arrow",
    $tooltipsContainer: $(".hp-current-offers"),
  });
});
